// @import "utilities/align";
// @import "utilities/background";
// Espresso themes
@each $theme-name, $values in $e-themes {
    .e-theme-#{$theme-name} {
        @include e-bg(e-theme-color($theme-name, "bg"), e-theme-color($theme-name, "border"));
        @include e-text(e-theme-color($theme-name, "text"), e-theme-color($theme-name, "headings"), e-theme-color($theme-name, "link"));
    }
}

.bg-black {
    background-color: $black !important;
}

.bg-left-top {
    background-position: 0 0 !important;
}

.bg-left-middle {
    background-position: 0 50% !important;
}

.bg-left-bottom {
    background-position: 0 100% !important;
}

.bg-center-top {
    background-position: 50% 0 !important;
}

.bg-center-middle {
    background-position: 50% 50% !important;
}

.bg-center-bottom {
    background-position: 50% 100% !important;
}

.bg-right-top {
    background-position: 100% 0 !important;
}

.bg-right-middle {
    background-position: 100% 50% !important;
}

.bg-right-bottom {
    background-position: 100% 100% !important;
}

.bg-cover {
    background-size: cover !important;
}

.bg-contain {
    background-size: contain !important;
}

// @import "utilities/borders";
.border-black {
    border-color: $black !important;
}

// @import "utilities/clearfix";
// @import "utilities/display";
// @import "utilities/embed";
// @import "utilities/flex";
// @import "utilities/float";
// @import "utilities/position";

.zi-sticky {
    z-index: $zindex-sticky !important;
}

.zi-fixed {
    z-index: $zindex-fixed !important;
}

@each $size, $length in $spacers {
    $scale: e-strip-unit($spacer) * 2;
    .pos-t-#{$size} {
        top: $scale * e-strip-unit($length) * 1%;
    }
    .pos-r-#{$size} {
        right: $scale * e-strip-unit($length) * 1%;
    }
    .pos-b-#{$size} {
        bottom: $scale * e-strip-unit($length) * 1%;
    }
    .pos-l-#{$size} {
        left: $scale * e-strip-unit($length) * 1%;
    }
}

// Offset

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {

        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $size, $length in $offset-size-vertical {
            .ot#{$infix}-#{$size} {
                transform: translateY(-$length);
            }
            .ob#{$infix}-#{$size} {
                transform: translateY($length);
            }
        }
        @each $size, $length in $offset-size-horizontal {
            .ol#{$infix}-#{$size} {
                left: -$length;
            }
            .or#{$infix}-#{$size} {
                right: -$length;
            }
        }
    }
}

// @import "utilities/screenreaders";
// @import "utilities/sizing";
// @import "utilities/spacing";
// @import "utilities/visibility";
// @import "utilities/text";
// Weight and italics
// Contextual colors
.text-black {
    color: $black !important;
}

.text-heading {
    color: $headings-color !important;
}

.text-link {
    color: $link-color !important;
}

.text-auto {
    color: inherit !important;
}

// Espresso text color schemes
.e-text-dark {
    @include e-text($yiq-text-dark !important, $yiq-headings-dark !important, $yiq-link-dark !important);
}

.e-text-light {
    @include e-text($yiq-text-light !important, $yiq-headings-light !important, $yiq-link-light !important);
}

.e-text-fixed-vertical-flow {
    h1,
    h2,
    h3,
    h4,
    p {
        margin-bottom: e-strip-unit( $paragraph-margin-bottom ) * 0.3125rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        line-height: $line-height-sm;
    }

    h4 {
        margin-bottom: e-strip-unit( $paragraph-margin-bottom ) * 0.1875rem;
    }

    .btn {
        margin-top: e-strip-unit( $paragraph-margin-bottom ) * 0.625rem;
    }
}

.text-muted {
    opacity: $text-muted;
}

.text-monospace {
    font-family: $font-family-monospace;
}

.text-decoration-none {
    &,
    &:hover {
        text-decoration: none;
    }
}

.line-height-sm {
    line-height: $line-height-sm;
}

.shadow-none {
    box-shadow: none !important;
}

.overflow-hidden {
    overflow: hidden;
}

// Espresso block link
.e-block-link {
    cursor: pointer;
}

// Espresso flex grow and shrink modifiers
.flex-grow-0 {
    flex-grow: 0;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-shrink-1 {
    flex-shrink: 1;
}

//Espresso transparent border
.border-transparent {
    border-color: transparent !important;
}
