.e-productlist {
    position: relative;
}

.e-productlist-loading-overlay {
    background: rgba($loading-bg, .95);
    z-index: $zindex-fixed - 2;

    .e-loading-spinner {
        top: 20vh;
    }
}

.e-productlist-product-count {
    &:empty {
        display: none;
    }

    &::before {
        content: '(';
    }

    &::after {
        content: ')';
    }
}


.e-productlist-header {
    padding-bottom: 0.5em;
}

.e-productlist-paging {

}

.e-productlist-footer {
}

