
.e-header-hamburger {
  //border-bottom: $border-width solid $border-color;

  .is-stuck & {
    border-color: rgba(0, 0, 0, 0);
  }

  .e-header-section-content {
    display: flex;
    align-items: center;
  }

  .e-nav-hamburger-header {
    margin-left: auto;
    //margin-right: (($canvas-padding-sm + e-strip-unit($grid-gutter-width/2)) * -1);
    margin-right: 0;
    flex: 0 1 auto;
  }
}


//@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
//    .e-header-hamburger {
//        display: none;
//    }
//}
