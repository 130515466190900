@include media-breakpoint-down($nav-hamburger-breakpoint) {
    .e-nav-item-cart {
        .e-header-hamburger & {
            .e-nav-pageicon {
            }
            .e-nav-pagename {
                &.is-hidden {
                    + .badge {

                    }
                }
            }
            .badge {
                position: absolute;
                top: 50%;
                right: 0%;
                transform: translate(-0%, -115%);
            }
        }
        .e-nav & {
            .nav-link {
                display: flex;
                align-items: center;
            }
            .badge {
                margin-left: $spacer / 2;
            }
        }
    }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    .e-nav-item-cart {
        .e-nav-pageicon {
        }
        .e-nav-pagename {
            &.is-hidden {
                + .badge {
                }
            }
        }
        .badge {
            position: absolute;
            top: 50%;
            right: 0%;
            transform: translate(0, -115%);
        }
    }
}
