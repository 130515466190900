.btn {
  &.btn-primary {
    transition: box-shadow $transition-fade-easing $transition-fade-speed;
    box-shadow: 0 6px 30px -10px theme-color("primary");
    &:hover {
      box-shadow: none;
    }
  }
  &.btn-secondary {
    transition: box-shadow $transition-fade-easing $transition-fade-speed;
    box-shadow: 0 6px 30px -10px theme-color("secondary");
    &:hover {
      box-shadow: none;
    }
  }
}