.p-icontext {
    margin-bottom: $paragraph-margin-bottom;
    :last-child {
        margin-bottom: 0;
    }
    .align-items-center {}
    .p-img {
        margin-bottom: $spacer / 2;
        position: relative;
        padding-top: 100%;
        // http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            max-height: 100%;
            .cls-1 {
                fill: transparent;
            }
        }
    }
    .p-img[style] {
        svg {
            fill: currentColor;
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: currentColor;
            }
        }
    }
    /*.p-txt-container.text-on-right {
        flex: 0;
        flex-grow: 1;
        min-width: 10%;
    }*/
    /*.p-txt-container.text-on-bottom {
        flex: 1 1 auto;
    }*/
    /*.icon-on-left {
        flex-grow: 0;
    }*/
    /*.icon-on-top {
        .row {
            margin: 0;
        }
    }*/
    .p-img-container {
        .icon-size-1 {
            width: 2em;
        }
        .icon-size-2 {
            width: 3em;
        }
        .icon-size-3 {
            width: 4em;
        }
        .icon-size-4 {
            width: 6em;
        }
        .icon-size-5 {
            width: 10em;
        }
        .icon-size-6 {
            width: 12em;
        }
    }
}

.p-icontext-white {
    svg {
        fill: $white;
        path,
        rect,
        circle,
        ellipse,
        line,
        polyline,
        polygon {
            stroke: $white;
        }
    }
}

.p-icontext-black {
    svg {
        fill: $black;
        path,
        rect,
        circle,
        ellipse,
        line,
        polyline,
        polygon {
            stroke: $black;
        }
    }
}

.p-icontext-light {
    svg {
        fill: $gray-400;
        path,
        rect,
        circle,
        ellipse,
        line,
        polyline,
        polygon {
            stroke: $gray-400;
        }
    }
}

.p-icontext-dark {
    svg {
        fill: $gray-600;
        path,
        rect,
        circle,
        ellipse,
        line,
        polyline,
        polygon {
            stroke: $gray-600;
        }
    }
}

.p-icontext-primary {
    svg {
        fill: theme-color("primary");
        path,
        rect,
        circle,
        ellipse,
        line,
        polyline,
        polygon {
            stroke: theme-color("primary");
        }
    }
}

.p-icontext-secondary {
    svg {
        fill: theme-color("secondary");

        path,
        rect,
        circle,
        ellipse,
        line,
        polyline,
        polygon {
            stroke: theme-color("secondary");
        }
    }
}

.p-icontext-tertiary {
    svg {
        fill: theme-color("tertiary");
        path,
        rect,
        circle,
        ellipse,
        line,
        polyline,
        polygon {
            stroke: theme-color("tertiary");
        }
    }
}

.p-icontext-quaternary {
    svg {
        fill: theme-color("quaternary");
        path,
        rect,
        circle,
        ellipse,
        line,
        polyline,
        polygon {
            stroke: theme-color("quaternary");
        }
    }
}

