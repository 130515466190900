.e-nav {
  transform: translate3d(101%,0,0);
}

.e-nav-global:before {
  display: none;
}

.modal-backdrop {
  background-color: $white;
  &.show {
    opacity: .85;
  }
}

.e-nav.modal {
  background: theme-color("primary");
  transition: transform $transition-collapse-speed / 2 $transition-collapse-easing;
  .e-nav-global {
    font-size: 5vw;
    font-family: $headings-font-family;
    background: none;
  }
  @include media-breakpoint-up($nav-hamburger-breakpoint) {
    border-radius: 10em 0 0 0;
    left: 26.65%;
    .e-nav-global {
      font-size: 2vw;
      .e-nav-pagename {
        transition: opacity $transition-collapse-speed / 2 $transition-collapse-easing, padding-right $transition-collapse-speed / 2 $transition-collapse-easing;
        &:hover {
          opacity: 0.75;
          padding-right: 1.25vw;
        }
      }
    }
  }
}

.e-logo {
  svg {
    transition: margin-left $transition-fade-easing $transition-fade-speed;
    g {
      transition: opacity $transition-fade-speed $transition-fade-easing, transform $transition-fade-speed $transition-fade-easing;
    }
    @include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
      display: block;
    }
    display: none;
    height: 100%;
  }
}

@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
  .e-header {
    transition: background $transition-fade-speed $transition-fade-easing, transform $transition-fade-speed $transition-fade-easing;
    &.is-transparent {
      &.reset-transform-containing-block {
        background: $white;
        .burgermenu span {
          background: theme-color("secondary");
        }
      }
      .burgermenu span {
        background: $white;
      }
    
      &.is-stuck {
        .e-logo {
          svg {
            margin-left: -67%;
            transition-delay: 0.5s;
            g {
              opacity: 0;
              transform: translateX(100%);
            }
          }
        }
        .burgermenu span {
          background: theme-color("secondary");
        }
      }
    }
  }
}

.e-nav-hamburger-header .burgermenu {
  position: relative;
  height: 1.25em;
  width: 2.5em;
  z-index: 5;

  &:hover {
    span:nth-child(2) {
      width: 100%;
    }
  }

  span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: theme-color("secondary");
    transition: transform $transition-fade-speed $transition-collapse-easing, width $transition-fade-speed $transition-collapse-easing, opacity $transition-fade-speed $transition-collapse-easing;
  }

  span:first-child {
    top: 0;
  }

  span:nth-child(2) {
    width: 80%;
    margin-left: auto;
    right: 0;
  }

  span:last-child {
    top: auto;
    bottom: 0;
  }
}

.reset-transform-containing-block {
  .e-nav-hamburger-header .burgermenu {
    &:hover {
      span:first-child {
        opacity: 1;
        transform: rotate(0);
      }

      span:last-child {
        opacity: 1;
        transform: rotate(0);
      }
    }
    span {
      opacity: 0;
      top: 50%;
    }

    span:first-child {
      opacity: 1;
      transform: rotate(45deg);
    }

    span:last-child {
      opacity: 1;
      transform: rotate(-45deg);
    }
  }
}