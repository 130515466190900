.p-imagetext-lr {
  .p-img-container {
    &:hover {
      //img:not(.blur) {
      //  transform: translateY(-2.5%);
      //}
      //img.blur {
      //  opacity: 0.5;
      //}
    }

    img {
      transition: opacity $transition-fade-speed $transition-fade-easing, transform $transition-fade-speed $transition-fade-easing;
      border-radius: 5em 0;

      &:not(.blur) {
        position: relative;
        z-index: 1;
      }
    }

    img.blur {
      filter: blur(35px);
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(3.5%) scale(0.85);
      opacity: 0.85;
    }
  }
}