.e-section {
    @include e-canvas-padding;
    @for $size from 1 through 5 {
        &.pt-#{$size},
        &.py-#{$size} {
            padding-top: ($spacer * $size) !important;
        }
        &.pb-#{$size},
        &.py-#{$size} {
            padding-bottom: ($spacer * $size) !important;
        }
    }
    &:not(.e-nav-local) {
        &.is-sticky {
            position: sticky;
            top: 0;
            z-index: $zindex-sticky;
            transition: padding $transition-collapse-speed / 2 $transition-collapse-easing, box-shadow $transition-fade-speed / 2 $transition-fade-easing;
        }
        &.is-stuck {
            box-shadow: $shadow-xs;
            &:not([class*="e-section-height-"]) {
                box-shadow: $shadow-md;
                //@for $size from 1 through 5 {
                //    &.pt-#{$size},
                //    &.py-#{$size} {
                //        padding-top: ($spacer / 3 * $size) !important;
                //    }
                //    &.pb-#{$size},
                //    &.py-#{$size} {
                //        padding-bottom: ($spacer / 3 * $size) !important;
                //    }
                //}
            }
        }
    }
}

// Espresso section height
@each $key, $value in $section-heights {
    .e-section-height-#{$key} {
        @include e-section-height($key);
    }
}

.e-section-height-fullscreen {
    min-height: 100vh;
}

.e-section-collapse {
    >.container-fluid {
        >.row {
            &::after {
                content: "";
                display: block;
                flex: 0 0 100%;
                order: 2;
            }
        }
    }
}

//Background size and position
.e-section-bg-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

//Collapse
.e-section-collapse-content {
    &:not(.show) {
        display: block;
        overflow: hidden;
        height: 25vh;
        &.is-sm {
            height: 12.5vh;
        }
        &.is-lg {
            height: 37.5vh;
        }
    }
    &::after {
        content: "";
        display: block;
        height: $spacer;
    }
}

.e-section-collapse-content.collapsing:not(.show) {
    //height: 25vh;
}

.e-section-collapse-toggle {
    z-index: 99;
    cursor: pointer;
    font-size: $font-size-sm;
    &::before {
        content: "";
        position: absolute;
        right: 0;
        left: 0;
        bottom: 100%;
        height: 0;
        transition: height $transition-collapse-speed * 2 $transition-collapse-easing;
    }
    &.collapsed {
        &::before {
            height: $spacer * 0.25;
            background: radial-gradient(50% 100% at 50% 100%, rgba(0, 0, 0, 0.15) 0%, transparent 100%), radial-gradient(50% 25% at 50% 100%, rgba(0, 0, 0, 0.35) 0%, transparent 100%);
        }
    }
}

.e-section-collapse-toggle-text {
    margin-top: $spacer * -0.25;
    display: block;
    font-size: $font-size-sm;
    &::after {
        content: attr(data-collapse-text);
    }
    .collapsed & {
        &::after {
            content: attr(data-expand-text);
        }
    }
}

.e-section-collapse-toggle-btn {
    display: block;
    padding: 0;
    height: $spacer;
    width: $spacer;
    line-height: 1;
    margin: 0 auto;
    font-size: $spacer;
    box-shadow: $shadow-sm;
    transform: translateY(-50%);
    transition: none;
    &:hover,
    &:active {
        // background: e-theme-color("dark", "bg");
        // color: $white;
        // border-color: e-theme-color("dark", "bg");
    }
}

.e-section-collapse-toggle-icon {
    line-height: 1.25;
    transform: rotate(540deg);
    transition: transform $transition-collapse-speed $transition-collapse-easing;
    .collapsed & {
        transform: rotate(0deg) translateY(0.0675em);
    }
}