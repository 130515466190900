.p-section {
    position: relative;
    border: 0 solid $border-color;
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
}

// Borders
.p-section-border-top {
    border-top-width: $border-width;
}

.p-section-border-bottom {
    border-bottom-width: $border-width;
}
