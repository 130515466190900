@import "e-header";
@import "bio-burgermenu";
@import "bio-scrollbar";
@import "buttons";
@import "e-paragraph-imagetext";
@import "e-footer";

//.e-section-height-fullscreen {
//  height: calc(100vh - #{$header-logo-height-lg} - #{$header-logo-margin-lg});
//  min-height: calc(100vh - #{$header-logo-height-lg} - #{$header-logo-margin-lg});
//}

body {
  padding-right: 0!important;
}

@include media-breakpoint-up($nav-hamburger-breakpoint) {
  .line {
    position: fixed;
    bottom: 0;
    top: 0;
    width: 1px;
    background: rgba(0, 0, 0, .03);
    z-index: 99999;
    pointer-events: none;

    &:first-child {
      left: 3.25%;
    }

    &:nth-child(2) {
      left: 26.65%;
    }

    &:nth-child(3) {
      left: 50%;
    }

    &:nth-child(4) {
      left: 73.35%;
    }

    &:nth-child(5) {
      left: 96.75%;
    }
  }
}