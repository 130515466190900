
.e-productlist-item {
    // TODO: Limit hover state to larger/non-touch devices.
    // TODO: Lav media query til target touch device
    position: relative;
    @include media-breakpoint-up(xl) {
        &:hover {
            border: 0;
        }
        &::before {
            content: "";
            display: none;
            position: absolute;
            top: $spacer / -2;
            bottom: $spacer / -2;
            left: $spacer / -2;
            right: $spacer / -2;
            z-index: 1;
            background-color: $body-bg;
            box-shadow: $shadow-md;
        }
        &:hover {
            z-index: 2;

            &::before {
                display: block;
            }

            .is-hidden-on-hover {
                display: none;
            }

            .is-visible-on-hover {
                display: block;
            }
        }
    }

    .is-hidden-on-hover {
        display: block;
    }

    .is-visible-on-hover {
        display: none;
    }
}

.e-productlist-item-container {
    position: relative;
    z-index: 2;
}

.e-productlist-item-image-container {
    position: relative;
    margin-bottom: $spacer;
    padding-top: percentage(3 / 4);
}

.e-productlist-item-image-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.e-productlist-item-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.e-productlist-item-text-container {
    a {
        color: inherit;
        text-decoration: none;
    }
}

.e-productlist-item-name {
    color: $headings-color;
}

.e-productlist-item-text {
}

.e-productlist-item-price {
}

