.e-dealersearch-sidebar {
    @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.e-dealersearch-header {
    flex: 0 1 auto;
}

.e-dealersearch-main {
    flex: 1 1 auto;
    @include media-breakpoint-down(sm) {
        max-height: 200vh;
    }
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
    }
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.5);
    }
}

.e-dealersearch-form {}

.e-dealersearch-input-placeholder {
    // height: $input-height-lg;
    padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
    border: $input-btn-border-width solid transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 1;
    transition: opacity $transition-fade-speed / 2 $transition-fade-easing;
    &.is-hidden {
        opacity: 0;
    }
}

.e-dealersearch-typeahead-wrapper {}

.e-dealersearch-suggestions {
    min-width: 100%;
    .dropdown-item {
        cursor: pointer;
    }
}
.e-dealersearch-suggestions-item-geolocation {
    .material-icons {
        color: $link-color;
    }
    &.active {
        .material-icons {
            color: inherit;
        }
    }
}

.e-dealersearch-list {
    opacity: 0;
    transition: $transition-fade;
    &:not(.is-empty) {
        opacity: 1;
        background: $white;
    }
}

.e-dealersearch-list-item {
    &:not(:last-child) {
        border-bottom: $border-width solid $border-color;
    }
    // &::after {
    //     content: '';
    //     display: block;
    //     height: $border-width;
    //     background: currentColor;
    //     opacity: 0.35;
    // }
}

.e-dealersearch-list-item-address {
    cursor: pointer;
}

.e-dealersearch-list-item-icon {
    .material-icons {
        padding-bottom: $spacer / 6;
    }
}

.e-dealersearch-googlemap-container {
    @include media-breakpoint-down(sm) {
        height: 0;
        overflow: hidden;
        &.is-active {
            height: auto;
        }
    }
}

.e-dealersearch-googlemap {
    height: 90vh;
}

.e-dealersearch-googlemap-infowindow {
    @include e-text-dark;
}
