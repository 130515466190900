.e-header {
  z-index: 9999;

  &.is-stuck {
    box-shadow: none;
  }

  &.is-transparent {
    &::before {
      display: none;
    }
  }
}

.e-nav-global > .nav > .nav-item > .nav-link {
  padding: 0.75em 1em;
  text-align: right;
}

.e-nav-utilities-primary {
  border: 0;
  margin: 0;
  padding-top: 0;
  .nav > .nav-item {
    margin-left: auto;
    .nav-link {
      padding: 1.0125em 2em;
    }
  }
}

.e-nav.modal {
  margin-top: calc(#{$header-logo-height-sm} + (#{$header-logo-margin-sm} * 2));
  @include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
    margin-top: calc(#{$header-logo-height-lg} + (#{$header-logo-margin-lg} * 2));
  }
}
