//Container styling. All sizes and flexbox props resides in _e-header.scss
.e-nav-hamburger-header {

  // All levels
  .nav-item {
    display: flex;
  }

  .nav-link {
    color: inherit;
    display: flex;
    align-items: center;
  }

  .e-nav-pageicon {
    color: $header-color;
    transform: scale(e-strip-unit($spacer));

    + .e-nav-pagename {
      margin-left: 0.875em;
    }
  }

  .e-nav-pagename {
    font-size: $font-size-sm;

    &.is-hidden {
      display: none;

    }
  }

  .e-nav-item-cart {

  }

  .e-nav-item-search {

  }

}

