
.e-nav-item-hamburger-close {
  .nav-link {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0;
    color: inherit;
  }

  .e-nav-pageicon {
    .show & {
      transform: rotate(360deg);
      transition: transform $transition-slide-speed linear $transition-slide-speed * 0.875;
    }
  }

  .e-nav-pagename {
    font-size: $font-size-sm;
    font-family: $h4-font-family;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    margin: 0 ($spacer / 2) ($spacer / 6);
    opacity: 0;

    .show & {
      //opacity: 1;
      //transition: opacity $transition-fade-speed $transition-fade-easing $transition-collapse-speed + $transition-slide-speed;
    }
  }
}

//
//@include media-breakpoint-up(breakpoint-next($nav-hamburger-breakpoint)) {
//    .e-nav-item-hamburger-close {
//        display: none;
//    }
//}

