// OBS. This files is closely related to _e-nav-local.scss so any changes made here, should probably also be made there.
.p-sitemap {
    .nav-link:not(.btn) {
        color: inherit;
    }

    .e-nav-pageicon {
        transform: scale(e-strip-unit($spacer));

        + .e-nav-pagename {
            margin-left: 0.875em;
        }
    }

    .e-nav-pagename {
    }

    .e-nav-pageicon-dropdown {
    }
}

.p-sitemap-horizontal {
    .p-sitemap-container {
    }

    .p-sitemap-heading {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 auto 0 0;

        h2 {
            color: inherit;
            font-size: 1.375em;
            font-weight: $font-weight-bold;
            margin: 0;
        }
    }

    // All levels
    .nav {
    }

    .nav-item {
    }

    .nav-link {
    }

    // Level #1
    .p-sitemap-container > .nav {
        > .nav-item {
            display: flex;
            align-items: center;

            > .nav-link {
                display: flex;
                align-items: center;
                line-height: $line-height-sm * 0.875;
                padding: ($spacer * .875) ($spacer * 1) ($spacer * .75);

                &.btn {
                    padding: ($spacer * .5) ($spacer * 1);
                    // @include button-variant();
                }
            }
        }
    }

    .dropdown-toggle {
        //Dropdown arrow
        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        font-size: 1em;

        .nav-link {
            padding: ($spacer * 0.5) ($spacer * 1.125);
        }
    }
}

.p-sitemap-vertical {
    .p-sitemap-heading {
        padding: 0;
        margin: 0;

        h2 {
            margin-bottom: $spacer * 0.25;
        }
    }

    // All levels
    .nav {
    }

    .nav-item {
        // Her skal muligvis tilføjes width:100% for at fixe en flex-bug i IE edge
    }

    .nav-link {
        padding: ($spacer * 0.3125) 0;
        line-height: $line-height-sm;
    }

    // All sub levels
    .nav {
        .nav {
            margin: ($spacer * 0.25) 0 ($spacer * 1) ($spacer * 1);

            .nav-item {
            }

            .nav-link {
            }
        }
    }

    // Level #1
    .p-sitemap-container > .nav {
        font-size: $font-size-sm;

        > .nav-item {
            border-top: $border-width solid $border-color;

            &:last-child {
                border-bottom: $border-width solid $border-color;
            }

            > .nav-link {
                font-weight: $font-weight-bold;
                padding: ($spacer * 0.675) 0;
            }
        }
    }

    // Level #2
    .p-sitemap-container > .nav {
        > .nav-item {
            > .nav {
                margin-top: 0;
            }
        }
    }
}
