.e-checkout {
    min-height: 100vh;

    .e-loading-overlay {
        background: rgba($loading-bg, .85);
    }
}

.e-checkout-step {
    + .e-checkout-step {
        border-top: $border-width solid $border-color;
    }

    .card-header {
        .badge {
            min-width: 3em;
        }
    }

    .card-body {
    }

    &.e-checkout-step-active {
        .card-header,
        .card-body {
            background: theme-color("info");
        }
    }

    &:not(.e-checkout-step-completed):not(.e-checkout-step-active) {
        .card-header {
            opacity: $text-muted / 2;

            .badge {
                background: color("gray");
            }
        }
    }
}

.e-checkout-step-summary {
    transition: max-height $transition-collapse-speed $transition-collapse-easing;
    overflow: hidden;
    max-height: 0;

    .e-checkout-step-completed & {
        max-height: 100vh;
    }
}

.e-checkout-step-summary-data {
    opacity: $text-muted / 2;

    &.e-checkout-step-summary-data-approve {
        opacity: 1;
        color: $headings-color;
        transition: opacity $transition-fade-speed $transition-fade-easing;
    }
}

.e-checkout-step-form {
    .form-check-label {
        margin-bottom: $spacer * 0.125;
    }

    .e-checkout-list-group-item-icon {
        height: $spacer * 1.25;
        width: auto;
    }
}

.e-checkout-receipt {
    .e-checkout-aside {
        .e-orderlines-summery-edit-cart {
            display: none;
        }
    }
}

.e-checkout-toggle-target-hidden {
    display: none;
}

.e-checkout-summery-icon {
    img {
        height: $spacer * 1.25;
        width: auto;
    }
}

@include media-breakpoint-down(md) {
    .e-checkout {
        min-height: 100vh;
    }
}

@include media-breakpoint-up(md) {
    .e-checkout-aside {
        position: sticky;
        top: $spacer;
    }
}
